import React from 'react';
import BaseDialog from "./BaseDialog";
import { Link } from "./Buttons";
import styled from 'styled-components';
import colors from 'resources/constants/colors';

const Body = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin: 0;
  color: black;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmDialog = ({open, onSubmit, onClose, config})=>{

  const actions = (
    <ActionsContainer>
      <Link color={config.options?.submitColor} onClick={onSubmit}>
        {config.options?.submitText ? config.options.submitText : 'OK'}
      </Link>
      <Link color={config.options?.cancelColor ? config.options.cancelColor : colors.cancel} onClick={onClose}>
        {config.options?.closeText ? config.options.closeText : 'Cancel'}
      </Link>
    </ActionsContainer>
  );

  return (
    <BaseDialog
      open={open}
      title={config.title}
      header={config.header}
      onClose={onClose}
      actions={actions}
      dismissible={true}
    >
      <Body>
        {config.description}
      </Body>
    </BaseDialog>
  );
}

export default ConfirmDialog;