import { getReservationCharge } from 'utils/charge';

const POLICIES = (data) => ({
    gracePeriod: { text: 'CARD_POLICY_GRACE_PERIOD' },
    charge: {
        text: 'CARD_POLICY_CANCEL_REQUEST',
        vars: { amount: getReservationCharge(`${data.reservation.date}T${data.reservation.time}`) },
    },
    bigGroups: { text: 'CARD_POLICY_BIG_GROUPS' },
    reservationTimeOptional: { text: 'CARD_POLICY_RSV_TIME_OPTL' },
});

export default POLICIES;
