const ES = {
    "HOME_TITLE": "Una petición de<br>reservación VIP en Quince",
    "REQUIRED": "* Obligatorio",
    "REQUIRED_FIELD" : "Este campo es obligatorio",
    "VISIT_WEBSITE": "Visitar sitio",
    "CLICK_FOR_NEXT_RESERVATION" : "Clic Aquí Para Realizar una Reservación Nueva",
    "VISIT_QUINCE_WEBSITE" : "Visit sitio de Quince",
    "CLOSE_WINDOW": "Cerrar esta ventana",
    "ALL_COUNTRIES": "Todos los países",
    "INVALID_PHONE": "Este no parece ser un número de teléfono válido",
    "EMPTY_PHONE": "Por favor llena este campo",
    "ERROR_AGREE_POLICY": "Usted tiene que aceptar las políticas para continuar.",

    "For": "Para",
    "By": "Por",
    "guest": "invitados",
    "Concierge": "Concierge",
    "Click bellow to": "Clic abajo para",
    "Table for": "Mesa para",
    "3_STEPS_TITLE": "3 simples pasos para reservar",
    "STEP_1": "PASO 1<br>Proporciona un email y un número de teléfono.",

    "CANCEL_TITLE": "Su cancelación fué exitosa",
    "CANCEL_TITLE_FAIL": "Su cancelación se ha hecho",
    "CANCEL_TITLE_AFTER_HOURS": "Su tiempo de reservación ya pasó y es demasiado tarde para cancelar.",
    "FAREWELL": "Esperamos verte pronto :)",
    "ANOTHER_RESERVATION": "Si está interesado, seleccione otra fecha para su siguiente reservación.",
    "MEANWHILE": "Por lo pronto puedes saber más acerca de nosotros",
    "KEEP_RESERVATION": "Mantener reservación",
    "CANCEL_DIALOG_TITLE": "¿Estás seguro de querer cancelar esta reservación?",
    "CANCEL_DIALOG_BODY": "Esta acción no se puede deshacer.",

    "POLICY_FAIL": "Usted canceló <b>sin 1 hora de anticipación</b> así que por favor revise nuestra <span>POLÍTICA DE CANCELACIÓN</span> que usted aceptó.",
    "POLICY_AFTER_HOURS": "Gracias por intentar notificarnos.<br>Por favor revise nuestra<br><span>POLÍTICA DE CANCELACIÓN</span><br>que aceptó requiere al menos <b>1 hora de aviso.</b>",
    "Cancellation Policy": "Política de cancelación",

    "REQUESTED_TIME_TITLE" : "Su consierge solicitó el siguiente horario que está disponible hasta ", 

    "EXPIRE_SORRY": "Oh, ¡lo sentimos!",
    "EXPIRE_INFO": "Su tiempo para completar la reservación expiró hace",
    "EXPIRE_HOURS_AGO": "hora(s).",
    "EXPIRE_MINUTES_AGO": "minuto(s).",
    "EXPIRE_SECONDS_AGO": "segundo(s).",
    "EXPIRE_STILL_INTERESTED": "Para volver a reservar, haga click en el botón.",
    "EXPIRE_REQUEST_AGAIN": "Reservar nuevamente",
    "EXPIRE_NO_INTEREST": "No me interesa volver a reservar",

    "CARD_POLICY_TITLE" : "PASO 2",
    "CARD_POLICY_TITLE_2" : "Por favor marca con un check",
    "CARD_POLICY_TITLE_3" : "para confirmar nuestras políticas.",
    "CARD_POLICY_GRACE_PERIOD": "Acepto que cuento con <b>15 minutos</b> de tiempo de espera y que pasado este tiempo mi mesa podrá ser entregada a otros clientes de la lista de espera.",
    "CARD_POLICY_CANCEL_REQUEST": "Acepto ser cobrado <b>$%amount% MXN / Persona</b> SI NO CANCELO CON AL MENOS UNA HORA DE ANTICIPACIÓN. CANCELACIÓN SOLO MEDIANTE <b>MI LINK DE CONFIRMACIÓN</b>.",
    "CARD_POLICY_TABLE_SPACING": "Por COVID, las mesas están distanciadas.",
    "CARD_POLICY_BIG_GROUPS": "Reservas de +10 pax podrían ubicarse en mesas separadas.",
    "CARD_POLICY_RSV_TIME_OPTL": "En días de alta demanda y días/semanas festivas, debido a la disponibilidad de mesas, es posible que su mesa no sea entregada en el momento exacto de su reserva.",

    "CARD_INFO_TITLE": "PASO 3<br>Por favor ingresa la información de tu tarjeta (encriptada por Stripe).",
    "CARD_OWNER": "Nombre en la tarjeta",
    "CARD_DETAILS": "Detalles de la tarjeta",
    "CARD_DOBLE_CHECK": "Verifique los detalles de su reserva y acepte nuestra política. La información de su tarjeta de crédito está segura.",

    "THANKS_TITLE": "¡Gracias!",
    "THANKS_SUBTITLE": "Su reservación ha sido confirmada",
    "THANKS_FOOTER": "Presiona el botón si necesitas cancelar por cualquier motivo",

    "FOOTER_TEXT": "Si tiene algún problema, póngase en contacto con el servicio de atención al cliente de su hotel.",
    "ALSO_YOU_CAN": "También puede",
    "CONFIRM_RESERVATION": "Confirmar reservación",
    "MODIFY_RESERVATION": "Quiero esta reservación",
    "CANCEL_RESERVATION": "Cancelar reservación",
    "CHANGE_MY_MIND" : "Cambie de idea,  favor de cancelar",
    "EXPIRATION_TEXT": "Tiempo para completar su reservación",
    "CLICK_CALL_US": "Llamarnos",
    "CONTACT_US": "Contactarnos",
    "CLICK_CANCEL": "Cancelar",
    "MAKE_RESERVATION": "Nueva Reservación",

    "REDIRECT_TITLE": "No logramos encontrar una reservación",
    "REDIRECT_FAREWELL": "Puedes agendar una en nuestro sitio:",

    "UPDATE_TITLE": "Modifica tu reservación",
    "UPDATE_MESSAGE": "Le podemos asistir personalmente. Por favor contacte nuestro servicio al cliente.",
    "CALL_TO_MODIFY": "Llamar para modificar",

    "MOBILE": "Teléfono Móvil",
    "EMAIL": "E-mail",
    "NAME": "Nombre",

    "Call us if you have any question": "Llámanos si tienes alguna pregunta",
    "If you need to cancel for any reason.": "Si necesitas cancelar por cualquier motivo.",
    "Or": "O",

    "SLOW_CONNECTION":"Esto está tardando más de lo usual, por favor NO ACTUALIZAR la página para terminar de cargar la información.",
    "CONFIRMING": "Confirmando reservación..."
}

export default ES;