import Types from './types';

export default (
  state = { active: false, message: null, type: '', log: [] },
  action
) => {
  switch (action.type) {
    case Types.ERROR:
      return {
        active: true,
        message: action.message,
        type: 'ERROR',
        log: state.log,
      };

    case Types.WARN:
      return {
        active: true,
        message: action.message,
        type: 'WARN',
        log: state.log,
      };

    case Types.INFO:
      return {
        active: true,
        message: action.message,
        type: 'INFO',
        log: state.log,
      };

    case Types.LOG:
      return {
        active: false,
        message: null,
        type: '',
        log: [...state.log, action.message],
      };

    case Types.DISMISS:
      return { active: false, message: null, type: '', log: state.log };

    default:
      return state;
  }
};
