import {takeLatest, put, all, call} from 'redux-saga/effects';
import {reservationsAPI} from 'resources/api';
import Types from './types';


function* cancelStartAsync({payload}) {
  try {
    const res = yield reservationsAPI.cancel(payload);
    yield put({type: Types.CANCEL_SUCCESS, payload: res});
  } catch (e) {
    yield put({type: Types.FETCH_ERROR});
  }
}

function* cancelStart() {
  yield takeLatest(Types.CANCEL_START, cancelStartAsync);
}

function* fetchStartAsync({payload}) {
  try {
    const res = yield reservationsAPI.checkin(payload);
    yield put({type: Types.FETCH_SUCCESS, payload: res});
  } catch (e) {
    yield put({type: Types.FETCH_ERROR});
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* pollStart() {
  yield takeLatest(Types.POLL_START, pollStartAsync);
}

function* pollStartAsync({payload}) {
  try {
    while (1) {
      const {status} = yield reservationsAPI.status(payload);
      if (status === 'pending') {
        yield new Promise(r => setTimeout(r, 1500));
        continue;
      } else if (status === "confirmed") {
        yield put({type: Types.STRIPE_SUCCESS});
        return;
      }
      return yield put({type: Types.STRIPE_FAILED});
    }
  } catch (e) {
    return yield put({type: Types.STRIPE_FAILED});
  }
}

export default function* sagas() {
  yield all([
    call(fetchStart),
    call(cancelStart),
    call(pollStart)
  ]);
}