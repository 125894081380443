import React from 'react';
import Lottie from 'react-lottie-player'
import OkIconAnimation from 'resources/animations/ok-icon.json';
import styled from 'styled-components';

const Container = styled.div`
    width: 64px;
    height: 64px;
`;

const OkIcon = () => {
    return (
        <Container>
            <Lottie
                animationData={OkIconAnimation}
                loop={false}
                play
            />
        </Container>
    );
};

export default OkIcon;
