import React from 'react';
import { Container, IconContainer, Icon, InputContainer, Input, Label, InvalidAlert, InvalidText } from './styles';
import CountrySelector from './CountrySelector';

export const CustomBlueInput = React.forwardRef(({ icon, id, title, labelFor, error, inputProps, containerProps }, ref) => {
  const defaultInputProps = { type: 'text' };
  const mergedInputProps = { ...defaultInputProps, ...inputProps };

  if (inputProps?.value) {
    if (inputProps.className) {
      mergedInputProps.className = `${inputProps.className} up`;
    } else {
      mergedInputProps.className = 'up';
    }
  }

  return (
    <Container {...containerProps}>
      {icon && (
        <IconContainer>
          <Icon src={icon} />
        </IconContainer>
      )}
      <InputContainer>
        <Input {...mergedInputProps} ref={ref} />
        <Label id={`label-${id}`} htmlFor={labelFor}>
          {title}
        </Label>
        {!!error && <InvalidAlert className="validationText">{error}</InvalidAlert>}
      </InputContainer>
    </Container>
  );
});

export const CustomMobileBlueInput = React.forwardRef(
  ({ className, type, name, autoComplete, id, value, onChange, country, onChangeCountry, required, title, labelFor, error }, ref) => {
    return (
      <>
        <Container className={className} id={id}>
          <CountrySelector
            preferredCountriesCodes={['MX', 'US']}
            value={country}
            onChange={(value) => {
              onChangeCountry(value);
            }}
          />
          <InputContainer>
            <Input
              type={type ?? 'text'}
              name={name}
              id={id}
              value={value ?? ''}
              onChange={onChange}
              required={required ?? false}
              className={!!value ? 'up' : ''}
              autoComplete={autoComplete}
              ref={ref}
            />
            <Label id={`label-${id}`} htmlFor={labelFor}>
              {title}
            </Label>
          </InputContainer>
        </Container>
        {!!error && <InvalidText className="validationText">{error}</InvalidText>}
      </>
    );
  }
);
