import styled from "styled-components";
import { useHistory } from "react-router-dom";
import QUINCE from "resources/img/logo.svg";
import Lang from "./Lang";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
  padding: 20px 10px;
  color: white;
  font-weight: bold;
`;

const BackSection = styled.div`
  flex-grow: 1;
  cursor : pointer;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const BackText = styled.span`
  margin: 3px;
`;

const LogoSection = styled.div`
  flex-grow: 3;
`;

const Logo = styled.img`
  height: 4rem;
`;

const LangSection = styled.div`
  flex-grow: 1;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;

const Header = ({ back }) => {
  const history = useHistory();

  return (
    <Container>
      <BackSection>
        {back && (
          <BackButton onClick={history.goBack}>
            <ArrowBackIcon />
            <BackText>Back</BackText>
          </BackButton>
        )}
      </BackSection>
      <LogoSection>
        <Logo src={QUINCE} />
      </LogoSection>
      <LangSection>
        <Lang />
      </LangSection>
    </Container>
  );
};

export default Header;
