import {
    RESERVATION_COST_CHANGE_HOUR,
    RESERVATION_PER_PERSON_COST_DAY,
    RESERVATION_PER_PERSON_COST_EVENING,
} from 'resources/constants/config';

const getPerPersonCost = (scheduledTime) => {
    try {
        const date = new Date(scheduledTime);
        const time = date.getHours() * 100 + date.getMinutes();
        const dayIndex = date.getDay();
        const costs = (
            time < RESERVATION_COST_CHANGE_HOUR ? RESERVATION_PER_PERSON_COST_DAY : RESERVATION_PER_PERSON_COST_EVENING
        ).split('|');
        return costs[dayIndex] ?? '400';
    } catch (e) {
        console.error(e);
        return '400';
    }
    
};

export const getReservationCharge = (scheduledTime) => {
    return getPerPersonCost(scheduledTime);
};
