export const SECONDS_IN_A_MINUTE = 60;
export const MILISECONDS_IN_A_SECOND = 1000;
export const MINUTES_IN_AN_HOUR = 60;

export function secondsToHelperText(secondsInteger){
    if (secondsInteger === false || secondsInteger < 0) return "";

    const counter = secondsFromMiliseconds(secondsInteger);
    const hours = hoursFromInteger(counter);
    const secondsInHours = hoursToSeconds(hours)
    const minutes = minutesFromInteger(counter - secondsInHours);

    return hours ? 'EXPIRE_HOURS_AGO' : ( minutes ? 'EXPIRE_MINUTES_AGO' : 'EXPIRE_SECONDS_AGO');
}

export function secondsToText(secondsInteger) {
    if (secondsInteger === false || secondsInteger < 0) return "-- : --";

    const counter = secondsFromMiliseconds(secondsInteger);

    const hours = hoursFromInteger(counter)
    const secondsInHours = hoursToSeconds(hours)

    const minutes = minutesFromInteger(counter - secondsInHours);
    const secondsInMinutes = minutesToSecods(minutes)

    const seconds = (counter - ( secondsInHours + secondsInMinutes )).toFixed(0);

    return `${hours ? formatNumber(hours)+" : " : ""} ${formatNumber(minutes)} : ${formatNumber(seconds)}`;
}

export function secondsFromMiliseconds(seconds){
    return seconds / MILISECONDS_IN_A_SECOND;
}

export function hoursFromInteger(secondsInteger) {
    const minutes = minutesFromInteger(secondsInteger);
    return Math.floor(minutes / MINUTES_IN_AN_HOUR)
}

export const hoursToSeconds = (hours) => {
    return hours * MINUTES_IN_AN_HOUR * SECONDS_IN_A_MINUTE;
}

export function minutesToSecods(minutes){
    return minutes * SECONDS_IN_A_MINUTE
}

export function minutesFromInteger(secondsInteger) {
    return Math.floor(secondsInteger / SECONDS_IN_A_MINUTE);
}


export function formatNumber(value) {
    if (isNaN(value)) return "--";

    return value > 9 ? value : "0" + value;
}

export const isExpired = ({createdAt, expirationSeconds}) => {
    
    const current  = new Date().getTime();
    const interval = new Date(createdAt).getTime() + (expirationSeconds * 1000)

    return current > interval;
}