import React, { useContext } from 'react';
import BaseDialog from 'alert/components/BaseDialog';
import { I18nContext } from 'i18n';
import styled from 'styled-components';
import useData from 'state/data';
import POLICIES from 'resources/constants/policies';

const Text = styled.p`
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 0;
    margin-bottom: 24px;
`;

const PolicyDialog = ({ id, open, onSubmit, onClose }) => {
    const [data] = useData();
    const { translate } = useContext(I18nContext);

    const COMPUTED_POLICES = POLICIES(data);

    return (
        <BaseDialog open={open} header={translate('Cancellation Policy')} onClose={onClose} actions={null} dismissible={true}>
            {Object.keys(COMPUTED_POLICES).map((POLICY) => (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: translate(COMPUTED_POLICES[POLICY].text, COMPUTED_POLICES[POLICY].vars),
                    }}
                />
            ))}
        </BaseDialog>
    );
};

export default PolicyDialog;
