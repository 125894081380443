import { CardElement } from '@stripe/react-stripe-js';
import { findCountryPhoneCode } from 'components/Inputs/CountrySelector/data';
import { parsePhoneNumber } from 'react-phone-number-input';
import { getFullPhoneNumber, isValidNumber } from 'utils/phone';

const validateAndSubmit = async (clientFormData, client, stripe, elements, translate, agreePolicy, language = 'en') => {

    if (!stripe || !elements) {
        throw new Error('');
    }

    if (!agreePolicy) {
        throw new Error(translate('ERROR_AGREE_POLICY'));
    }

    if (!isValidNumber(findCountryPhoneCode(clientFormData.phone.countryCode), clientFormData.phone.number)) {
        throw new Error(translate('INVALID_PHONE'));
    }

    const parsedNumber = parsePhoneNumber(getFullPhoneNumber(findCountryPhoneCode(clientFormData.phone.countryCode), clientFormData.phone.number));

    const result = await stripe.confirmCardSetup(
        client.stripeIntentId,
        {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: clientFormData.name,
                    email: clientFormData.email,
                    phone: getFullPhoneNumber(parsedNumber.countryCallingCode, parsedNumber.nationalNumber),
                },
                metadata: {
                    code: client.stripeCustomerId,
                    name: clientFormData.name,
                    email: clientFormData.email,
                    phone: parsedNumber.nationalNumber,
                    countryCode: parsedNumber.countryCallingCode,
                    language: language,
                },
            },
        }
    );

    if(result.error) throw new Error(result.error.message);
}

export default validateAndSubmit;