const TYPE = `[DATA]`;

export default {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    CANCEL_START: `${TYPE} CANCEL START`,
    CANCEL_SUCCESS: `${TYPE} CANCEL SUCCESS`,
    CANCEL_ERROR: `${TYPE} CANCEL ERROR`,

    UPDATE_CLIENT: `${TYPE} UPDATE CLIENT`,

    POLL_START : `${TYPE} POLLING FOR STRIPE SUCCESS`,
    STRIPE_SUCCESS:  `${TYPE} STRIPE SUCCESS`,
    STRIPE_FAILED:  `${TYPE} STRIPE FAILED`,
}