const API = process.env.REACT_APP_API_URL;

export const OPENTABLE_RID = process.env.REACT_APP_OPENTABLE_RID;
export const CLIENT_SERVICE_PHONE = process.env.REACT_APP_CLIENT_SERVICE_PHONE;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const WEBSITE_RESERVE_URL = process.env.REACT_APP_WEBSITE_RESERVE_URL;
export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME;
export const STRIPE_CODE =  process.env.REACT_APP_STRIPE_CODE;
export const STRIPE_SERVER_URL = `${API}/reservations/stripe/customer`

export const RESERVATION_PER_PERSON_COST_DAY = process.env.REACT_APP_RESERVATION_PER_PERSON_COST_DAY;
export const RESERVATION_PER_PERSON_COST_EVENING = process.env.REACT_APP_RESERVATION_PER_PERSON_COST_EVENING;
export const RESERVATION_COST_CHANGE_HOUR = process.env.REACT_APP_RESERVATION_COST_CHANGE_HOUR;
