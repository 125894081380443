import React from "react";
import Layout from "containers/Layout";
import Header from "components/Header";
import Body from "containers/Body";
import Footer, { DefaultFooterContent } from "containers/Footer";

const index = () => {
  return (
    <Layout>
      <Header />
      <Body>
        <h1>NOT FOUND - 404</h1>
      </Body>
      <Footer>
        <DefaultFooterContent />
      </Footer>
    </Layout>
  );
};

export default index;
