import React, { useContext, useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { Strong } from 'components/Texts';
import { I18nContext } from 'i18n';
import styled from 'styled-components';
import CheckSVG from 'resources/img/check-icon.svg';
import SquareCheckbox from 'components/SquareCheckbox';
import { Text } from 'components/Texts';
import useData from 'state/data';
import POLICIES from 'resources/constants/policies';

const CheckboxContainer = styled(FormGroup)`
  margin: 0;
  .credit-card-accept {
    font-weight: 400;
    font-size: 1.2rem;
    flex: 1;
  }
`;

const Row = styled.div`
  display: flex;
  margin: 0;
  margin-top: 2rem;
  text-align: justify;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  & span {
    line-height: 1.6;
  }
`;

const Check = styled.img`
  width: 15px;
  margin-bottom: -2px;
`;

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([key, value], index) => [key, fn(value, key, index)]));

const CreditCardPolicy = ({ setAgreePolicy }) => {
  const { translate } = useContext(I18nContext);
  const [data] = useData();
  const COMPUTED_POLICES = POLICIES(data);
  const [policies, setPolicies] = useState(objectMap(COMPUTED_POLICES, () => false));

  const changePolicy = (key) => {
    const newPolicies = { ...policies };
    newPolicies[key] = !policies[key];
    setPolicies(newPolicies);
  }

  useEffect(() => {
    let allAccepted = true;
    for(const value of Object.values(policies)) {
      if(!value) {
        allAccepted = false;
      }
    }
    setAgreePolicy(allAccepted);
  }, [policies]);

  console.log('COMPUTED_POLICES',COMPUTED_POLICES);

  return (
      <>
          <Strong style={{ maxWidth: '70vw', margin: '4rem auto 1rem auto' }}>
              {translate('CARD_POLICY_TITLE')}
              <br />
              {translate('CARD_POLICY_TITLE_2')}
              {' ('}
              <Check src={CheckSVG} />
              {') '}
              {translate('CARD_POLICY_TITLE_3')}
          </Strong>
          <CheckboxContainer>
              {Object.keys(COMPUTED_POLICES).map((POLICY) => (
                  <Row onClick={() => changePolicy(POLICY)}>
                      <SquareCheckbox onClick={() => {}} checked={policies[POLICY]} />
                      <Text
                          className="credit-card-accept"
                          margin={'0px'}
                          dangerouslySetInnerHTML={{
                              __html: translate(COMPUTED_POLICES[POLICY].text, COMPUTED_POLICES[POLICY].vars),
                          }}
                      />
                  </Row>
              ))}
          </CheckboxContainer>
      </>
  );
};

export default CreditCardPolicy;
