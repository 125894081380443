import React, { useContext } from "react";
import { AcceptButton, CancelButton } from "components/Buttons";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAlert } from "alert";
import alertTypes from "alert/types";
import { I18nContext } from "i18n";
import colors from "resources/constants/colors";
import { useUID } from "hooks/UIDProvider";
import useData from "state/data";

const ButtonContainer = styled.div`
  padding: 1rem 0;
`;

const WantTheReservation = ({handleIWant}) => {
  const { translate } = useContext(I18nContext);
  const alert = useAlert();
  const history = useHistory();
  const uid = useUID();
  const [, dispatcher] = useData();

  const onCancel = (e) => {
    e.preventDefault();
    alert({
      type: alertTypes.CONFIRM,
      config: {
        header: translate("CANCEL_RESERVATION"),
        title: translate("CANCEL_DIALOG_TITLE"),
        description: translate("CANCEL_DIALOG_BODY"),
        options: {
          submitText: translate("CANCEL_RESERVATION"),
          submitColor: colors.cancel,
          closeText: translate("KEEP_RESERVATION"),
          cancelColor: colors.accept,
        },
      },
    })
      .then(() => {
        dispatcher.cancelStart(uid);
        history.push("/cancel");
      })
  };

  return (
    <div>
      <ButtonContainer>
        <AcceptButton
          text={translate("MODIFY_RESERVATION")}
          onClick={handleIWant}
        />
      </ButtonContainer>
      <ButtonContainer>
        <CancelButton
          text={translate("CHANGE_MY_MIND")}
          onClick={onCancel}
        />
      </ButtonContainer>
    </div>
  );
};

export default WantTheReservation;
