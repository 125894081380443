import React, { useContext } from "react";
import {
  SimpleInfo,
  HighlightInfo,
} from "components/InfoItems";
import CalendarIcon from 'resources/img/calendar-icon.svg';
import ClockIcon from 'resources/img/clock-icon.svg';
import CustomerIcon from 'resources/img/customer-icon.svg';
import ChairIcon from 'resources/img/chair-icon.svg';
import OriginIcon from 'resources/img/origin.svg';
import ConciergeIcon from 'resources/img/concierge.svg';
import { getFormattedDate, getFormattedTime } from "utils/date";
import useData from "state/data";
import { getFormattedNameCard } from "utils/text";
import { I18nContext } from "i18n";

const Info = () => {
  const [data] = useData();
  const { translate, selectedLang } = useContext(I18nContext);
  return (
    <>
      <HighlightInfo icon={CustomerIcon} text={translate('For')} highlightedText={getFormattedNameCard(data.client)} />
      {data.reservation.origin && <HighlightInfo text={translate('By')} highlightedText={data.reservation.origin} icon={OriginIcon} />}
      {data.reservation.concierge && <HighlightInfo text={translate('Concierge')} highlightedText={data.reservation.concierge} icon={ConciergeIcon}/>}
      <SimpleInfo text={getFormattedDate(data.reservation.date, undefined, selectedLang)} icon={CalendarIcon} />
      <SimpleInfo text={getFormattedTime(data.reservation.time, undefined, selectedLang)} icon={ClockIcon} />
      <SimpleInfo text={`${translate('Table for')} ${data.reservation.personsQty}`} icon={ChairIcon} />
    </>
  );
};

export default Info;
