import moment from 'moment'
import 'moment/locale/es';
import { langs } from 'i18n/i18n.helper';

const DATE_FORMAT = 'dddd, D MMM YYYY';
const TIME_FORMAT = 'h:mm A';

export const getToday = (format = DATE_FORMAT, locale = 1) => {
    moment.locale(langs[locale]);
    const today = moment().format(format)

    return capitalize(today)
}

const capitalize = string => string.split(" ").map(item => item.charAt(0).toLocaleUpperCase() + item.slice(1)).join(' ')

export const getCurrentTime = () => {
    const today = moment().format(DATE_FORMAT)
    return today;
}

export const getFormattedDate = (date, format = DATE_FORMAT, locale = 1) => {
    moment.locale(langs[locale]);
    const dateFormatted = moment(date).format(format);

    return capitalize(dateFormatted);
}

export const getFormattedTime = (time, format = TIME_FORMAT, locale = 1) => {
    moment.locale(langs[locale]);

    //Added a dummy date, only time is needed
    const dateFormatted = moment(`1970-01-01 ${time}`).format(format);

    return capitalize(dateFormatted);

}
