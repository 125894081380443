import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

export const getFullPhoneNumber = (countryCode, number) => {
  return `${getFormattedCountryCode(countryCode)} ${number}`;
};

export const getFormattedCountryCode = (countryCode) => {
  return `+${countryCode}`;
};

export const getCountryLetterCode = (countryCode, number) => {
  const formNumber = getFullPhoneNumber(countryCode, number);
  try {
    const parsedNumber = parsePhoneNumber(formNumber);
    return parsedNumber ? parsedNumber.country : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const isValidNumber = (countryCode, number) => {
  try {
    const formNumber = getFullPhoneNumber(countryCode, number);
    const parsedNumber = parsePhoneNumber(formNumber);
    return !!parsedNumber && isValidPhoneNumber(formNumber);
  } catch (e) {
    return false;
  }
};