import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "resources/constants/colors";
import { Title } from 'components/Texts';
import { I18nContext } from "i18n";
import { Ring } from "@uiball/loaders";

const Container = styled.div`
  min-height: 6rem;
  margin-top: 8rem;
`;

const Loading = () => {
  const [show, setShow] = useState(false);
  const { translate } = useContext(I18nContext);

  useEffect(()=>{
    const timeot = setTimeout(()=>{
      setShow(true);
    }, 6000);

    return () => {
      clearTimeout(timeot);
    }
  },[]);

  return (
    <Container>
      <Ring size={42} color={colors.accept} />
      <div style={{height: '55px'}}></div>
      {show && <Title>{translate('SLOW_CONNECTION')}</Title>}
    </Container>
  );
};

export default Loading;
