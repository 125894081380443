import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { I18nContext } from "i18n";
import useTimer from "hooks/useTimer";

import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  color: white;
  background-color: ${(props) => (props.isRed ? "#E84961" : "black")};
  min-height: 2em;
`;

const ExpirationText = styled.p`
  display: inline-block;
  width: 65%;
  text-align: left;
  font-size: 1.2em;
  @media (max-width: 300px) {
    font-size: 0.9em;
  }
`;

const ExpirationTime = styled.p`
  display: inline-block;
  text-align: right;
  font-size: 2em;
  font-weight: bolder;
  width: 30%;
  @media (max-width: 640px) {
    font-size: 1.5em;
  }
  @media (max-width: 300px) {
    font-size: 1.2em;
  }
`;

const ExpirationCounter = () => {
  const { translate } = useContext(I18nContext);
  const [countDown, countDownText, isExpired] = useTimer();

  const history = useHistory();

  const EXPIRATION_INTERVAL = 120000; // 120k miliseconds, is 2 minutes,

  useEffect(() => {
    isExpired && history.push("/expired");
  }, [isExpired]);

  return (
    <Container isRed={countDown < EXPIRATION_INTERVAL}>
      <ExpirationText>{translate("EXPIRATION_TEXT")}</ExpirationText>
      <ExpirationTime>{countDownText}</ExpirationTime>
    </Container>
  );
};

export default ExpirationCounter;
