import styled from "styled-components";
import colors from 'resources/constants/colors';

export const Link = styled.a`
    color : ${({ color }) => color ? color : colors.accept};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
    margin: ${({ margin }) => margin ? margin : '10px 0'};
    font-weight : bold;
    font-size : 1.4rem;
    cursor: pointer;
`;