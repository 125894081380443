import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import { AcceptButton, CancelButton } from 'components/Buttons';
import { I18nContext } from 'i18n';
import { Title, Text } from 'components/Texts';
import styled from 'styled-components';
import useTimer from 'hooks/useTimer';
import { useAlert } from "alert";
import alertTypes from "alert/types";
import colors from "resources/constants/colors";
import useData from 'state/data';
import { useUID } from 'hooks/UIDProvider';

const TextSection = styled.div`
  padding: 3rem;
`;

const ButtonSection = styled.div`
  padding: 1rem;
`;

const Expired = () => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const [, countDownText, , helperText] = useTimer();
  const alert = useAlert();
  const uid = useUID();
  const [, dispatcher] = useData();

  const onCancel = (e) => {
    e.preventDefault();
    alert({
      type: alertTypes.CONFIRM,
      config: {
        header: translate("CANCEL_RESERVATION"),
        title: translate("CANCEL_DIALOG_TITLE"),
        description: translate("CANCEL_DIALOG_BODY"),
        options: {
          submitText: translate("CANCEL_RESERVATION"),
          submitColor: colors.cancel,
          closeText: translate("KEEP_RESERVATION"),
          cancelColor: colors.accept,
        },
      },
    })
      .then(() => {
        dispatcher.cancelStart(uid);
        history.push("/cancel");
      })
  };

  return (
    <Layout>
      <Header />
      <Body>
        <Title>{translate('EXPIRE_SORRY')}</Title>
        <TextSection>
          <Text>{translate('EXPIRE_INFO')}</Text>
          <Title>
            {countDownText} {helperText}
          </Title>
          <Text>{translate('EXPIRE_STILL_INTERESTED')}</Text>
        </TextSection>
        <ButtonSection>
          <AcceptButton text={translate('EXPIRE_REQUEST_AGAIN')} onClick={() => history.push('/update')} />
        </ButtonSection>
        <ButtonSection>
          <CancelButton text={translate('EXPIRE_NO_INTEREST')} onClick={onCancel} />
        </ButtonSection>
      </Body>
    </Layout>
  );
};

export default Expired;
