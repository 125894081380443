import { createContext, useReducer } from 'react';
import { getFormattedNameCard } from "utils/text";
import { getCountryLetterCode } from "utils/phone";

const initialState = {
    email: "",
    phone: {
        number: "",
        countryCode: ""
    },
    name: ""
}

function reducer(state, action) {

    switch (action.type) {
        case 'email': return { ...state, email: action.payload }
        case 'name': return { ...state, name: action.payload }
        case 'countryCode': return {
            ...state,
            phone: {
                number: state.phone.number,
                countryCode: action.payload
            }
        }
        case 'number': return {
            ...state,
            phone: {
                number: action.payload,
                countryCode: state.phone.countryCode
            }
        }
        case 'init': return init(state, action.payload)

        default: throw new Error('Unknown action')
    }
}

function init(state, payload) {
    let number = '';
    let countryCode = 'MX';

    if(!!payload.countryCode && !!payload.phone) {
        number = payload.phone;
        countryCode = getCountryLetterCode(payload.countryCode, payload.phone);
    }
    const name = getFormattedNameCard(payload)
    const email = payload.email;

    const phone = {
        number,
        countryCode,
    }

    return { ...state, name, phone , email };
}

export const ClientDataContext = createContext();

export const ClientDataProvider = ({ children }) => {
    const [clientData, dispatch] = useReducer(reducer, initialState);

    return (<ClientDataContext.Provider value={[clientData, dispatch]} > { children}  </ClientDataContext.Provider >)
}
