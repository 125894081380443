import React, { useContext } from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import { AcceptButton, ColorButton } from 'components/Buttons';
import { Title, Text } from 'components/Texts';
import { I18nContext } from 'i18n';
import styled from 'styled-components';
import { WEBSITE_RESERVE_URL } from 'resources/constants/config';
import colors from 'resources/constants/colors';
import { useAlert } from 'alert';
import alertTypes from 'alert/types';
import PolicyDialog from './alert/PolicyDialog';
import { CLIENT_SERVICE_PHONE } from 'resources/constants/config';
import Separator from 'components/Separator';

const ButtonContainer = styled.div`
  padding: 10px 20px;
  margin-top: 16px;
`;

const POLICY = styled(Text)`
  line-height: 2.4rem;
  font-size: 1.4rem;
  span {
    color: ${colors.accept};
    font-weight: 700;
    text-decoration: underline;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Button = styled(ColorButton)`
  color: ${colors.accept};
  &:hover {
    color: ${colors.accept};
  }
`;

const hrefTel = `tel:${CLIENT_SERVICE_PHONE}`;

const AfterHours = () => {
  const { translate } = useContext(I18nContext);
  const alert = useAlert();

  const showAlert = (e) => {
    e.preventDefault();
    alert({
      type: alertTypes.CUSTOM,
      config: {
        props: {},
        body: PolicyDialog,
      },
    })
      .then(() => {})
      .catch(() => console.log('Cancel'));
  };

  return (
    <Layout>
      <Header />
      <Body>
        <div style={{ height: '90px' }}></div>
        <Title style={{ margin: '0 10px', fontSize: '2rem' }}>{translate('CANCEL_TITLE_AFTER_HOURS')}</Title>
        <POLICY
          margin="43px 0 0 0"
          dangerouslySetInnerHTML={{
            __html: translate('POLICY_AFTER_HOURS'),
          }}
          onClick={showAlert}
        />
        <div style={{ height: '130px' }}></div>
        <Text>{translate('Click bellow to')}</Text>
        <ButtonsContainer>
          <a href={hrefTel}>
            <Button color={'#ECFAFF'}>{translate('CONTACT_US')}</Button>
          </a>
          <a href={WEBSITE_RESERVE_URL}>
            <AcceptButton text={translate('MAKE_RESERVATION')} />
          </a>
        </ButtonsContainer>
      </Body>
    </Layout>
  );
};

export default AfterHours;
