import styled from 'styled-components';
import colors from 'resources/constants/colors';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.base};
    min-height: ${({withTimer})=>withTimer ? 'calc(100vh - 4em)' : '100vh'};
    margin-top: ${({withTimer})=>withTimer ? '4em' : '0'}
`;

export default Layout
