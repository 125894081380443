import React, { createContext, useState, useEffect } from "react";
import { getTranslation } from "./i18n.helper.js";

export const I18nContext = createContext();

export const I8nProvider = ({ children }) => {
    const [selectedLang, setSelectedLang] = useState(1);

    useEffect(() => {
        const langFromStorage = localStorage.getItem('lang');
        const initLang = langFromStorage ? +langFromStorage : 1;
        setSelectedLang(initLang)
    }, []);

    const values = {
        selectedLang,
        setSelectedLang: (lang) => {
            setSelectedLang(lang)
            localStorage.setItem('lang', lang);
        },
        translate: (value, vars) => getTranslation(value, vars, selectedLang)
    }

    return (<I18nContext.Provider value={values}>{children}</I18nContext.Provider>);
};
