const EN = {
    "HOME_TITLE": "A VIP reservation<br>request at Quince",
    "REQUIRED" : "* Required",
    "REQUIRED_FIELD" : "This field is required",
    "VISIT_WEBSITE" : "Visit website",
    "CLICK_FOR_NEXT_RESERVATION" : "Click Here To Make A New Reservation",
    "VISIT_QUINCE_WEBSITE" : "Visit Quince website",
    "CLOSE_WINDOW" : "Close this window",
    "ALL_COUNTRIES": "All Countries",
    "INVALID_PHONE": "This phone number doesn't appear to be valid",
    "EMPTY_PHONE": "Please fill out this field",
    "ERROR_AGREE_POLICY": "You must to agree to the policie to continue.",

    "For": "For",
    "By": "By",
    "guest": "guest",
    "Concierge": "Concierge",
    "Click bellow to": "Click bellow to",
    "Table for": "Table for",
    "3_STEPS_TITLE": "3 simple steps to reserve",
    "STEP_1": "STEP 1<br>Provide email and phone number.",

    "CANCEL_TITLE": "Your cancellation was succesful.",
    "CANCEL_TITLE_FAIL": "Your cancellation has been made.",
    "CANCEL_TITLE_AFTER_HOURS": "Your reservation time has past and it is too late to cancel.",
    "FAREWELL": "We hope to see you very soon :)",
    "ANOTHER_RESERVATION": "If you have interest, select another time for your next reservation.",
    "MEANWHILE": "Meanwhile you can know more about us.",
    "KEEP_RESERVATION": "Keep reservation",
    "CANCEL_DIALOG_TITLE": "Are you sure want to cancel this reservation?",
    "CANCEL_DIALOG_BODY": "This action cannot be undone.",

    "POLICY_FAIL": "You cancelled <b>without 1-hour notice</b> so please be aware of our <span>CANCELLATION POLICY</span> that you agreed.",
    "POLICY_AFTER_HOURS": "Thank you for trying	to notify us.<br>Please see our<br><span>CANCELLATION POLICY</span><br>you agreed to that requires <b>1-hour notice.</b>",
    "Cancellation Policy": "Cancellation Policy",

    "REQUESTED_TIME_TITLE" : "Your concierge requested the following time which is available until ", 

    "EXPIRE_SORRY": "Oh, so sorry!",
    "EXPIRE_INFO": "Your time to complete your reservation expired",
    "EXPIRE_HOURS_AGO": "hour(s) ago.",
    "EXPIRE_MINUTES_AGO": "minute(s) ago.",
    "EXPIRE_SECONDS_AGO": "second(s) ago.",
    "EXPIRE_STILL_INTERESTED": "If you are still interested, please select below.",
    "EXPIRE_REQUEST_AGAIN": "Request reservation time again",
    "EXPIRE_NO_INTEREST": "No interest in booking reservation",

    "CARD_POLICY_TITLE" : "STEP 2",
    "CARD_POLICY_TITLE_2" : "Please put a check",
    "CARD_POLICY_TITLE_3" : "below to confirm our policies.",
    "CARD_POLICY_GRACE_PERIOD": "I agree there is only <b>15 minute grace</b> period and after this my table may be provided to other waiting guests.",
    "CARD_POLICY_CANCEL_REQUEST": "I agree to be charged <b>$%amount% MXN / PERSON</b> IF I DON'T CANCEL AT LEAST ONE HOUR BEFORE MY RESERVATION TIME. CANCEL ONLY VIA <b>YOUR CONFIRMATION LINK.</b>",
    "CARD_POLICY_TABLE_SPACING": "Due to COVID restrictions tables have to respect a certain distance.",
    "CARD_POLICY_BIG_GROUPS": "Reservations of +10 pax may be seated at separate tables.",
    "CARD_POLICY_RSV_TIME_OPTL": "During high season and holidays, due to the availability of tables, your table may not be ready at the exact time of your reservation.",
    
    "CARD_INFO_TITLE" : "STEP 3<br>Please provide your credit card information (encrypted by Stripe).",
    "CARD_OWNER" : "Card owner",
    "CARD_DETAILS" : "Card details",
    "CARD_DOBLE_CHECK" : "PLEASE DOUBLE CHECK YOUR RESERVATION DETAILS AND AGREE TO OUR POLICY. YOUR CREDIT CARD INFO IS SECURE.",
    
    "THANKS_TITLE": "Thank you!",
    "THANKS_SUBTITLE": "Your reservation has been booked",
    "THANKS_FOOTER": "Press the button if you need to cancel for any reason",

    "FOOTER_TEXT" : "If you have any problem, please contact your hotel customer service.",
    "ALSO_YOU_CAN" : "Also you can",
    "CONFIRM_RESERVATION" : "Confirm reservation",
    "MODIFY_RESERVATION" : "I want this reservation",
    "CANCEL_RESERVATION" : "Cancel reservation",
    "CHANGE_MY_MIND" : "I changed my mind, please cancel",
    "EXPIRATION_TEXT" : "Expiration time to complete",
    "CLICK_CALL_US": "Call Us",
    "CONTACT_US": "Contact Us",
    "CLICK_CANCEL": "Cancel",
    "MAKE_RESERVATION": "Make Reservation",

    "REDIRECT_TITLE": "We can't find a reservation.",
    "REDIRECT_FAREWELL": "You can book one at our website:",

    "UPDATE_TITLE": "Modify your reservation",
    "UPDATE_MESSAGE": "We can assist you personally. Press to contact our customer service.",
    "CALL_TO_MODIFY": "Call to Modify",

    "MOBILE": "Mobile Phone",
    "EMAIL": "E-mail",
    "NAME": "Name",

    "Call us if you have any question": "Call us if you have any question",
    "If you need to cancel for any reason.": "If you need to cancel for any reason.",
    "Or": "Or",
    
    "SLOW_CONNECTION":"This is taking longer than usual, please DON'T REFRESH the page to finish loading the info.",
    "CONFIRMING": "Confirming reservation..."
}

export default EN;