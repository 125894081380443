import { useEffect } from 'react';
import { isExpired } from 'utils/time';
import useData from 'state/data';
import {useHistory} from 'react-router-dom';
import { useUID } from 'hooks/UIDProvider';

function useRedirection() {
    
    const history = useHistory();
    const [data, dispatcher] = useData();
    const uid = useUID();

    useEffect(() => {
        dispatcher.fetchStart(uid);
    }, [uid]);

    useEffect(()=>{
        if(data.error) history.push('/redirect');
        if(!data.reservation.statusCode) return;

        switch(data.reservation.statusCode){
            case 'cancelled':
                history.push("/cancel");
                break;
            case 'hasattended': 
            case 'hasnotattended':            
            case 'charged':                          
            case 'confirmed':
                history.push("/thank-you");
                break;
            default:
                if(isExpired(data.reservation)) history.push('/expired');
        }
        
    }, [data]);
}

export default useRedirection;