import React from 'react';
import MaterialDialog from '@material-ui/core/Dialog';
import CloseSVG from 'resources/img/close-icon.svg';
import styled from 'styled-components';
import colors from 'resources/constants/colors';

const DialogContainer = styled(MaterialDialog)`
    .MuiDialog-container {
        margin: 0 auto;
        width: 100%;
    }
    .MuiPaper-root {
        width: 100%;
        padding: 0px;
        margin: 0 22px;
        overflow: visible;
        border-radius: 8px;
    }
`;

const Close = styled.img`
    position: absolute;
    right: 0;
    top: -22px;
    width: 20px;
    cursor: pointer;
`;

const HeaderContainer = styled.div`
    background-color: ${colors.backgroundDarkBlue};
    padding: 24px 16px;
    color: white;
    border-radius: 8px 8px 0px 0px;
`;

const Header = styled.h3`
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin: 0;
`;

const Content = styled.div`
  padding: 16px 16px;
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: black;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const BaseDialog = ({
    open,
    title,
    header,
    actions,
    onClose,
    children,
}) => {
    return (
        <div>
            <DialogContainer
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                {onClose && <Close src={CloseSVG} alt="close" onClick={onClose}/>}
                {header && <HeaderContainer><Header>{header}</Header></HeaderContainer>}
                <Content>
                  <Title>{title}</Title>
                  <DialogContent>{children}</DialogContent>
                  {actions && <div>{actions}</div>}
                </Content>
            </DialogContainer>
        </div>
    );
};

export default BaseDialog;
