import React, { useState, useContext } from "react";
import styled from "styled-components";
import colors from "resources/constants/colors";
import Es from "resources/img/es.svg";
import En from "resources/img/en.svg";
import Down from "@material-ui/icons/KeyboardArrowDown";
import Up from "@material-ui/icons/KeyboardArrowUp";
import {I18nContext} from "i18n/index";

const Container = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-width: 80px;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  width: 3em;
  align-items: center;
`;

const ContentFlag = styled.div`
  display: flex;
  width : 3em;
  align-items: center;
`;

const Flag = styled.img`
  overflow: hidden;
  border-radius: 5px;
  width : 3em;
`;

const List = styled.ul`
  background-color: white;
  overflow: hidden;
  border-radius: 5px;
  padding-left: 0;
  color: #fff;
  position: absolute;
  z-index: 1000;
  right : 28px;
  top: 60px;
  padding : 0 22px;
  box-shadow: 0px 8px 24px #0332693D;
`;

const ItemList = styled.li`
  list-style-type: none;
  text-align: left;
  margin: 16px 0; 
`;

const LangSpan = styled.span`
  color: ${colors.textMain};
  margin: 5px;
  font-weight: 400;
  width : 3rem;
  font-size : 1.2rem;
`;

const LangSpanDisplay = styled(LangSpan)`
  color: white;
`;

const Lang = () => {
  const [visible, setVisible] = useState(false);

  const { selectedLang, setSelectedLang } = useContext(I18nContext);

  const langs = [
    [<Flag src={Es}/>, "ES"],
    [<Flag src={En}/>, "EN"],
  ];

  const changeSelection = (index) => {
    setSelectedLang(index);
  };

  const toggle = () => setVisible(!visible);

  return (
    <>
      <Container onClick={toggle}>
        <ContentFlag>{langs[selectedLang][0]}</ContentFlag>
        <Content>
          <LangSpanDisplay>{langs[selectedLang][1]}</LangSpanDisplay>
        </Content>
        <Content>{visible ? <Up /> : <Down />}</Content>
      </Container>
      {visible && (
        <List onClick={toggle}>
          {langs.map((lang, index) => (
            <ItemList key={index} onClick={() => changeSelection(index)}>
              <Container>
                <ContentFlag>{lang[0]}</ContentFlag>
                <Content>
                  <LangSpan>{lang[1]}</LangSpan>
                </Content>
              </Container>
            </ItemList>
          ))}
        </List>
      )}
    </>
  );
};

export default Lang;
