import Axios from "axios";

const consumer = (domain, resource) => {
  const url = `${domain}/${resource}`;

  const headers = {};

  return {
    checkin: async (uid) => {
      try {
        const response = await Axios.get(`${url}/checkin/${uid}`, {
          headers,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    cancel: async (uid) => {
      try {
        const response = await Axios.get(`${url}/cancel/${uid}`, {headers});
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    status: async (uid) => {
      try {
        const response = await Axios.get(`${url}/status/${uid}`, {headers});
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};

export default consumer;


