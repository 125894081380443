import React, { useContext } from "react";
import styled from "styled-components";

import { I18nContext } from "i18n";

export const FooterText = styled.p`
    font-size: 1.1rem;
    font-weight: 300;
    margin: ${({ margin }) => (margin ? margin : '3rem')};
`;

const Footer = styled.div`
  padding: 16px;
  color: white;
`;

export const DefaultFooterContent = () => {
  const { translate } = useContext(I18nContext);

  return <FooterText>{translate("FOOTER_TEXT")}</FooterText>;
};

export default Footer;
