import styled from 'styled-components';
import colors from "./colors.js";
import {components} from "react-select";


const OptionContainer = styled.div`
    display: inline-flex;
`;

const OptionLabel = styled.p`
    margin: 0;
    padding-right: 10px;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.textMain};
`;

export const formatOptionLabel = (data) => (
  <OptionContainer>
    <OptionLabel>{`${data.emoji}`}</OptionLabel>
    <OptionLabel>{`${data.native}`}</OptionLabel>
    <OptionLabel>{`+${data.phone}`}</OptionLabel>
  </OptionContainer>
);

const GroupLabel = styled.p`
    margin: 0;
    padding-right: 10px;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${colors.textMain};
`;

export const formatGroupLabel = (data) => (
    <GroupLabel>{data.label}</GroupLabel>
);

const ValueLabel = styled.p`
    margin: auto 0;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.textMain};
`;

export const SingleValue = ({ children, ...props }) => {
  return <ValueLabel>{`${props.data.emoji} +${props.data.phone}`}</ValueLabel>;
};

const GroupContainer = styled.div`
    div:not([class]), section[class=""] {
        display: grid;
        grid-template-columns: auto;
    }
`;
  
export const Group = props => (
    <GroupContainer>
        <components.Group {...props} />
    </GroupContainer>
);

export const StylesObject = {
    control: () => ({
        display: 'flex',
        alignItems: 'center',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        ':focused': {
            outline: 'none',
            border: 'none',
        },
        ':hover': {
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
        },
    }),
    valueContainer: () => ({
        display: 'flex',
    }),
    indicatorsContainer: () => {
        return {};
    },
    dropdownIndicator: () => {
        return {
            padding: '2px',
            color: '#388CFF',
            'svg': {
                height: '15px',
            }
        };
    },
    option: () => {
        return {
            textAlign: 'left',
            display: 'inline-flex',
            width: 'auto',
            padding: '5px 10px',
        };
    },
    menu: () => {
        return {
            zIndex: '2',
            display: 'flex',
            position: 'absolute',
            backgroundColor: `${colors.backgroundLightGrey}`,
        };
    },
    placeholder: base => ({
        ...base,
        fontSize: '14px',
        fontWeight: 400,
        paddingLeft: '20px',
        color: '#388CFF',
    }),
    singleValue: base => ({
        ...base,
        fontSize: '12px',
    }),
};