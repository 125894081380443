import React from 'react'
import './Separator.css'
import styled from "styled-components";

export const SeparatorText = styled.span`
font-size: 1.5em;
`;

const Separator = ({children}) => <div className="separator">{children}</div>

export default Separator
