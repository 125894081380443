import React, { useContext } from 'react';
import { Label as InputLabel } from 'components/Inputs/styles';
import CardSection from './CardSection';
import styled from 'styled-components';
import { Strong } from 'components/Texts';
import { I18nContext } from 'i18n';
import { ClientDataContext } from '../../../providers/ClientDataProvider';
import { CustomBlueInput } from 'components/Inputs';
import CustomerIcon from 'resources/img/customer-icon.svg';
import { ErrorText } from 'components/Texts';

const CardInner = styled.div`
  margin: 2rem;
`;

const InputContainer = styled.div`
  margin: 15px 0;
`;

export default function CardSetupForm() {
  const { translate } = useContext(I18nContext);
  const [clientData, dispatch] = useContext(ClientDataContext);

  const handleChange = (e) => {
    const name = e.target.value;

    dispatch({
      type: 'name',
      payload: name,
    });
  };

  return (
    <>
      <CardInner>
        <Strong style={{ maxWidth: '70vw', margin: '1rem auto' }} dangerouslySetInnerHTML={{__html: translate('CARD_INFO_TITLE')}}/>
        <InputContainer>
          <CustomBlueInput
            title={translate('CARD_OWNER')}
            icon={CustomerIcon}
            inputProps={{
              value: clientData.name,
              onChange: handleChange,
              required: true,
              name: 'name',
            }}
            required={true}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>{translate('CARD_DETAILS')}</InputLabel>
          <CardSection />
        </InputContainer>
      </CardInner>
    </>
  );
}
