import React, { useEffect } from 'react';
import './App.css';
import { Switch, Route, useHistory } from 'react-router-dom';
import Home from 'pages/home';
import Update from 'pages/update';
import Thanks from 'pages/thanks';
import Cancel from 'pages/cancel';
import AfterHours from 'pages/cancel/AfterHours';
import CannotCancel from 'pages/cancel/CannotCancel';
import Expired from 'pages/expired';
import NotFound from 'pages/404';
import Redirect from 'pages/redirect';
import { AlertServiceProvider } from "alert";
import { UIDProvider, UIDRequiredRoute } from "hooks/UIDProvider";

const App = () => {
  const history = useHistory();
  
  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
  <div className="App">
    <UIDProvider>
      <AlertServiceProvider>
        <Switch>
            <UIDRequiredRoute path='/' exact={true}>
              <Home />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/update'>
              <Update />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/thank-you'>
              <Thanks />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/cancel'>
              <Cancel />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/cancel-fail'>
              <CannotCancel />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/cancel-fail-after-hours'>
              <AfterHours />
            </UIDRequiredRoute>
            <UIDRequiredRoute path='/expired'>
              <Expired />
            </UIDRequiredRoute>
            <Route path='/redirect' component={Redirect} />
            <Route component={NotFound} />
        </Switch>
      </AlertServiceProvider>
    </UIDProvider>
  </div>
)};


export default App;
