import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 25px;
  min-width: 25px;
`;

const Mark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 2px solid ${colors.accept};
  border-radius: 4px;
  :after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid ${colors.accept};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  &.checked {
    :after {
      display: block;
    }
  }
`;

const SquareCheckbox = React.forwardRef(({ onClick, disabled, checked }, ref) => {
  return (
    <Container onClick={onClick}>
      <Mark className={checked ? 'checked' : ''} />
    </Container>
  );
});

export default SquareCheckbox;
