import React, { useContext, useEffect } from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import Form from './components/Form';
import ExpirationCounter from 'components/ExpirationCounter';
import { Title } from 'components/Texts';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_CODE } from 'resources/constants/config';
import { useUID } from 'hooks/UIDProvider';
import { I18nContext } from 'i18n';
import useData from 'state/data';
import useRedirection from './hooks/useRedirection';
import { ClientDataProvider } from './providers/ClientDataProvider';
import Loading from './components/Loading';

const stripePromise = loadStripe(STRIPE_CODE);

const Home = () => {
  const { translate } = useContext(I18nContext);
  const uid = useUID();
  const [data, dispatcher] = useData();

  useRedirection();

  useEffect(() => {
    dispatcher.fetchStart(uid);
  }, [uid]);

  return (
    <ClientDataProvider>
      <Layout withTimer={true}>
        <Header />
        <Body>
          {data.isFetching ? (
            <Loading />
          ) : (
            <>
              <Title dangerouslySetInnerHTML={{__html: translate('HOME_TITLE')}} />
              <div style={{ height: '25px' }}></div>
              <Elements stripe={stripePromise}>
                <Form />
              </Elements>
            </>
          )}
        </Body>
      </Layout>
      <ExpirationCounter />
    </ClientDataProvider>
  );
};

export default Home;
