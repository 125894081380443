import React, { useContext } from "react";
import Layout from "containers/Layout";
import Header from "components/Header";
import Body from "containers/Body";
import Footer, { DefaultFooterContent } from "containers/Footer";
import { AcceptButton } from "components/Buttons";
import { Title, Text } from "components/Texts";
import { I18nContext } from "i18n";
import styled from "styled-components";
import { WEBSITE_URL } from "resources/constants/config";

const ButtonContainer = styled.div`
  padding: 10px 50px;
  margin-top: 16px;
`;

const Redirect = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Layout>
      <Header />
      <Body>
        <Title>{translate("REDIRECT_TITLE")}</Title>
        <Text margin="20px 0 0 0">{translate("REDIRECT_FAREWELL")}</Text>
        <ButtonContainer>
          <a href={WEBSITE_URL}>
            <AcceptButton text={translate("VISIT_WEBSITE")} />
          </a>
        </ButtonContainer>
      </Body>
      <Footer>
        <DefaultFooterContent />
      </Footer>
    </Layout>
  );
};

export default Redirect;
