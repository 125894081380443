import { countries } from 'countries-list';

const formattedCountries = Object.keys(countries).map((code) => ({
    label: countries[code].name,
    value: code,
    ...countries[code],
}));


const getCountriesList = (countries) => {
    const preferredCountries = [];
    const rest = [];

    formattedCountries.forEach((country)=>{
        if(countries.includes(country.value)) {
            preferredCountries.push(country);
        } else {
            rest.push(country);
        }
    });

    return {preferredCountries, rest};
};

export const getGroupedOptions = (countries, restLabel) => {
    
    const {preferredCountries, rest} = getCountriesList(countries);
    
    const groupedOptions = [
        {
          label: "",
          options: preferredCountries
        },
        {
          label: restLabel,
          options: rest
        }
    ];
    return groupedOptions;
};

export const findCountryPhoneCode = (phoneCode) => {
    return findCountry(phoneCode)?.phone;
}

export const findCountryTextCode = (phoneCode) => {
    return findCountry(phoneCode)?.value;
}

export const findCountry = (countryValue) => {
    return formattedCountries.find((country)=>countryValue===country.value);
}

