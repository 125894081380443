import styled from 'styled-components';

const Body = styled.div`
    background-color: white;
    border-radius: 24px 24px 0px 0px;
    padding: 32px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export default Body;
