import React, { useContext, useEffect } from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import { AcceptButton } from 'components/Buttons';
import { Title, Text } from 'components/Texts';
import { I18nContext } from 'i18n';
import styled from 'styled-components';
import { OPENTABLE_RID, WEBSITE_RESERVE_URL } from 'resources/constants/config';
import Separator from 'components/Separator';

const ButtonContainer = styled.div`
  padding: 10px 20px;
`;

const OT_SCRIPT_CONTAINER_ID = "ot-script-container";

const Cancel = () => {
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `//www.opentable.com/widget/reservation/loader?rid=${OPENTABLE_RID}&type=standard&theme=standard&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website`;
    script.async = true;

    const scriptContainer = document.getElementById(OT_SCRIPT_CONTAINER_ID);
    if(scriptContainer?.childElementCount === 0) scriptContainer.appendChild(script);
    
    return () => {
      try{
        scriptContainer.removeChild(script);
      } catch (e){
        console.error(e);
      }  
    }
  }, []);

  return (
    <Layout>
      <Header />
      <Body>
        <Title>{translate('CANCEL_TITLE')}</Title>
        <Text margin="70px 0 0 0">{translate('ANOTHER_RESERVATION')}</Text>
        <div style={{ height: '20px' }}></div>
        <div id={OT_SCRIPT_CONTAINER_ID}></div>
        <Separator>
          <Text margin={'8px 0'}>{translate('Or')}</Text>
        </Separator>
        <ButtonContainer>
          <a href={WEBSITE_RESERVE_URL}>
            <AcceptButton text={translate('CLICK_FOR_NEXT_RESERVATION')} />
          </a>
        </ButtonContainer>
      </Body>
    </Layout>
  );
};

export default Cancel;
