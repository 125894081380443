import { useEffect, useState, useContext } from "react";
import useData from "state/data";
import { I18nContext } from 'i18n';
import { secondsToText, MILISECONDS_IN_A_SECOND, secondsToHelperText } from "utils/time";

const useTimer = () => {
  const [data] = useData();
  const [countDown, setCountDown] = useState();
  const [expiration, setExpiration] = useState();
  const [countDownText, setcountDownText] = useState("-- : --");
  const [isExpired, setIsExpired] = useState(false);
  const [helperText, setHelperText] = useState();
  const { selectedLang, translate } = useContext(I18nContext);

  useEffect(() => {
    const createdAt = data.reservation.createdAt;
    const expirationSeconds = data.reservation.expirationSeconds;

    const SAFETY_PERIOD = 10;

    const expirationsToMiliseconds =
      (expirationSeconds - SAFETY_PERIOD) * MILISECONDS_IN_A_SECOND;

    const newExpiration =
      new Date(createdAt).getTime() + expirationsToMiliseconds;

    setExpiration(newExpiration);
  }, [data.reservation]);

  useEffect(() => {
    const tickReference = setInterval(() => {
      const current = new Date().getTime();
      const newCountDown = expiration - current;
      setCountDown(newCountDown);

      if (newCountDown < 0) {
        setIsExpired(true);
        const newCountDownText = secondsToText(newCountDown * -1);
        setcountDownText(newCountDownText)
      } else {
        const newCountDownText = secondsToText(newCountDown);
        setcountDownText(newCountDownText)
      }
    }, 1000);

    return () => clearInterval(tickReference);
  }, [expiration]);

  useEffect(()=>{
    if (countDown < 0) {
      setHelperText(translate(secondsToHelperText(countDown * -1)));
    } else {
      setHelperText(translate(secondsToHelperText(countDown)));
    }
  }, [selectedLang, translate, countDown]);

  return [countDown, countDownText, isExpired, helperText];
};


export default useTimer;