import Types from "./types";

export default class ProductsDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  fetchStart = (uid) => {
    this.dispatch({type: Types.FETCH_START, payload: uid});
  };

  fetchSuccess = ({payload}) =>
    this.dispatch({type: Types.FETCH_SUCCESS, payload});

  fetchFailure = () =>
    this.dispatch({type: Types.FETCH_ERROR, payload: {}});

  cancelStart = (uid) => this.dispatch({type: Types.CANCEL_START, payload: uid});

  pollStart = (uid) => this.dispatch({type: Types.POLL_START, payload: uid});

  updateClientData = (clientFormData) => this.dispatch({type: Types.UPDATE_CLIENT, payload: clientFormData});

}