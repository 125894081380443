import styled from 'styled-components';
import colors from 'resources/constants/colors';

export const Title = styled.h1`
    color: ${colors.textMain};
    font-size: 2.4rem;
    font-weight: 400;
    margin : 0;
`;

export const Subtitle = styled.h2`
    color: ${colors.textMain};
    font-size: 2.2rem;
    font-weight: 400;
    margin : 0;
`;

export const Strong = styled.p`
    color: ${colors.textMain};
    font-size: 1.4rem;
    font-weight: 600;
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
    margin: ${({ margin }) => (margin ? margin : '')};
`;

export const Text = styled.p`
    font-size: 1.6rem;
    color : ${({ color }) => (color ? color : colors.textMain)};
    margin: ${({ margin }) => (margin ? margin : '')};
`;

export const TextSmall = styled.p`
    font-size: 1.25rem;
    color : ${colors.textMain};
`;

export const ErrorText = styled(TextSmall)`
    color : ${colors.cancel}
`;