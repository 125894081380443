import React from 'react';
import styled from 'styled-components';
import colors from 'resources/constants/colors';

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 18px;
  justify-items: start;
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
`;

const Icon = styled.img`
  width: 40px;
  height: auto;
`;

const Text = styled.p`
  color: ${colors.textMain};
  font-size: 1.4rem;
  font-weight: 400;
  margin: auto 0;
`;

const Bold = styled(Text)`
  font-weight: 700;
`;

export const Info = ({ icon, margin, children }) => (
  <InfoContainer margin={margin}>
    <Icon src={icon} /> {children}
  </InfoContainer>
);

export const SimpleInfo = ({ text, margin, icon }) => (
  <Info icon={icon} margin={margin}>
    <Text>{text}</Text>
  </Info>
);

export const HighlightInfo = ({ text, highlightedText, margin, icon }) => (
  <Info icon={icon} margin={margin}>
    <div style={{ display: 'flex' }}>
      <Text>{text}</Text>
      <Bold style={{ marginLeft: '5px' }}>{highlightedText}</Bold>
    </div>
  </Info>
);