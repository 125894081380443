import React, {useContext, useEffect} from 'react';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import Card from 'containers/Card';
import {CancelButton, AcceptButton} from 'components/Buttons';
import {Title, Strong, Text} from 'components/Texts';
import styled from 'styled-components';
import OkIcon from './components/OkIcon';
import {I18nContext} from 'i18n';
import useData from 'state/data';
import {getFormattedDate, getFormattedTime} from 'utils/date';
import {SimpleInfo} from 'components/InfoItems';
import CalendarIcon from 'resources/img/calendar-icon.svg';
import ClockIcon from 'resources/img/clock-icon.svg';
import CustomerIcon from 'resources/img/customer-icon.svg';
import MobileIcon from 'resources/img/mobile-icon.svg';
import MailIcon from 'resources/img/mail-icon.svg';
import ChairIcon from 'resources/img/chair-icon.svg';
import OriginIcon from 'resources/img/origin.svg';
import ConciergeIcon from 'resources/img/concierge.svg';
import {CLIENT_SERVICE_PHONE} from 'resources/constants/config';
import {useAlert} from 'alert';
import alertTypes from 'alert/types';
import colors from 'resources/constants/colors';
import {useUID} from 'hooks/UIDProvider';
import {useHistory} from 'react-router-dom';
import {getFullPhoneNumber} from 'utils/phone';
import {formatPhoneNumberIntl} from 'react-phone-number-input';
import {getFormattedNameCard} from 'utils/text';
import { Ring } from '@uiball/loaders';

const hrefTel = `tel:${CLIENT_SERVICE_PHONE}`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Thanks = () => {
  const {selectedLang, translate} = useContext(I18nContext);
  const [data, dispatcher] = useData();
  const alert = useAlert();
  const uid = useUID();
  const history = useHistory();

  const onCancel = (e) => {
    e.preventDefault();
    alert({
      type: alertTypes.CONFIRM,
      config: {
        header: translate('CANCEL_RESERVATION'),
        title: translate('CANCEL_DIALOG_TITLE'),
        description: translate('CANCEL_DIALOG_BODY'),
        options: {
          submitText: translate('CANCEL_RESERVATION'),
          submitColor: colors.cancel,
          closeText: translate('KEEP_RESERVATION'),
          cancelColor: colors.accept,
        },
      },
    })
      .then(() => {
        if (!data.reservation?.date) {
          history.push('/cancel-fail-after-hours');
          return;
        }

        const reservationTime = new Date(`${data.reservation.scheduledTimeUTC}`);
        if (reservationTime.getTime() - 3600 * 1000 > new Date().getTime()) {
          dispatcher.cancelStart(uid);
          history.push('/cancel');
          return;
        }
        if (reservationTime.getTime() < new Date().getTime()) {
          history.push('/cancel-fail-after-hours');
          return;
        }
        history.push('/cancel-fail');
      })
      .catch(() => console.log('Cancel'));
  };

  useEffect(() => {
    if (data.confirmationStatus === 'failed') {
      history.push('/expired');
    }
  }, [data.confirmationStatus])

  return (
    <Layout>
      <Header back/>
      <Body>
        {
          data.confirmationStatus === 'confirmed' ?
            <div>
              <Title>{translate('THANKS_TITLE')}</Title>
              <IconContainer>
                <OkIcon/>
              </IconContainer>
              <Strong style={{textAlign: 'center', margin: '0 0 20px 0'}}>{translate('THANKS_SUBTITLE')}</Strong>
            </div> :
            <div>
              <Title>{translate('CONFIRMING')}</Title>
              <Ring size={42} color={colors.accept} />
            </div>
        }
        <Card padding="16px 12px" margin="10px 0">
          <SimpleInfo text={getFormattedNameCard(data.client)} icon={CustomerIcon}/>
          <SimpleInfo text={getFormattedDate(data.reservation.date, undefined, selectedLang)} icon={CalendarIcon}/>
          <SimpleInfo text={getFormattedTime(data.reservation.time, undefined, selectedLang)} icon={ClockIcon}/>
          <SimpleInfo text={data.client.email} icon={MailIcon}/>
          <SimpleInfo
            text={formatPhoneNumberIntl(getFullPhoneNumber(data.client.countryCode, data.client.phone))}
            icon={ChairIcon}
          />
          <SimpleInfo text={`${translate('Table for')} ${data.reservation.personsQty} ${translate('guest')}`}
                      icon={MobileIcon}/>
          {data.reservation.origin && <SimpleInfo text={data.reservation.origin} icon={OriginIcon}/>}
          {data.reservation.concierge && <SimpleInfo text={data.reservation.concierge} icon={ConciergeIcon}/>}
        </Card>
        <Card padding="16px 12px" margin="0 0 20px 0">
          <Text>{translate('Click bellow to')}</Text>
          <ButtonsContainer>
            <a href={hrefTel}>
              <AcceptButton text={translate('CLICK_CALL_US')}/>
            </a>
            <CancelButton text={translate('CLICK_CANCEL')} onClick={onCancel}/>
          </ButtonsContainer>
        </Card>
      </Body>
    </Layout>
  );
};

export default Thanks;
