import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom';

const UIDContext = React.createContext(Promise.reject);

export const useUID = () => React.useContext(UIDContext);

export const UIDProvider = ({ children }) => {

  const location = useLocation();
  const [uid,] = useState(new URLSearchParams(location.search).get('uid'));

  return (
      <UIDContext.Provider
        value={uid}
        children={children}
      />
  );
};

export const UIDRequiredRoute = ({ children, ...rest }) => {

    const uid = useUID();
    return (
      <Route
        {...rest}
        render={({ location }) =>
        typeof uid === 'string' && uid !== '' ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: '/redirect',
                  state: { from: location },
                }}
              />
            )
        }
      />
    );
}