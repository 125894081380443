import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorText } from 'components/Texts';
import { AcceptButton } from 'components/Buttons';
import { I18nContext } from 'i18n';
import Info from '../Info';
import Card from 'containers/Card';
import CreditCardPolicy from './CreditCardPolicy';
import StripeForm from './StripeForm';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import useData from 'state/data';
import validateAndSubmit from '../../validate';
import { ClientDataContext } from '../../providers/ClientDataProvider';
import ContactInfoForm from './ContactInfoForm';
import WantTheReservation from './WantTheReservation';
import { Subtitle } from 'components/Texts';
import styled from 'styled-components';
import {useUID} from "../../../../hooks/UIDProvider";

const STEP_INIT = 0;
const STEP_WANTTHIS = 1;

const ButtonContainer = styled.div`
  margin: 1rem 3rem;
`;

const FormContainer = () => {
  const { selectedLang, translate } = useContext(I18nContext);
  const [clientData, dispatch] = useContext(ClientDataContext);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [data, dispatcher] = useData();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [step, setStep] = useState(STEP_INIT);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const uid = useUID()

  useEffect(() => {
    if (!('phone' in data.client)) return;

    dispatch({
      type: 'init',
      payload: data.client,
    });
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      await validateAndSubmit(clientData, data.client, stripe, elements, translate, agreePolicy, selectedLang === 1 ? 'en' : 'es');

      dispatcher.updateClientData(clientData);
      dispatcher.pollStart(uid);
      history.push('/thank-you');
    } catch (e) {
      if (e.message !== '') setError(e.message);
    }

    setLoading(false);
  };

  useEffect(()=>{
    if(step===STEP_INIT) return;
    const element = document.querySelector("#contact-info-container");
    element.scrollIntoView({ behavior: 'smooth'});
  },[step]);

  useEffect(()=>{
    if(!agreePolicy) return;
    const element = document.querySelector("#confirm-reservation-container");
    element.scrollIntoView({ behavior: 'smooth'});
  },[agreePolicy]);

  return (
    <form onSubmit={handleSubmit} autocomplete="off">
      <Card backgroundColor="white" padding="16px 12px">
        <Info />
        {step === STEP_INIT && <WantTheReservation handleIWant={() => setStep(STEP_WANTTHIS)} />}
      </Card>
      {step > STEP_INIT && (
        <>
          <Subtitle style={{ margin: '30px 0 15px 0' }}>{translate('3_STEPS_TITLE')}</Subtitle>
          <Card id={'contact-info-container'} backgroundColor="white" padding="16px 12px">
            <ContactInfoForm />
            <CreditCardPolicy agreePolicy={agreePolicy} setAgreePolicy={setAgreePolicy} />
            {!data.isFetching && data.client.stripeCustomerId && <StripeForm />}
          </Card>
          {error && <ErrorText>{error}</ErrorText>}
          <ButtonContainer id={'confirm-reservation-container'}>
            <AcceptButton disabled={loading || !agreePolicy} type="submit" text={translate('CONFIRM_RESERVATION')} />
          </ButtonContainer>
        </>
      )}
    </form>
  );
};

export default FormContainer;
