import styled from 'styled-components';
import colors from 'resources/constants/colors';

export const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: ${colors.backgroundLightGrey};
  border-radius: 10px;
  padding: 14px;
  position: relative;
  &.top{
    align-items: flex-start;
  }
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

export const IconContainerPassword = styled(IconContainer)`
  width: 30px;
  height: 20px;
  padding: 0 5px;
  box-sizing: border-box;
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

export const IconPassword = styled.img`
  width: 20px;
  height: 20px;
`;

const LabelUpStyles = 'top: -9px; font-size: 1.2rem;';

export const InputContainer = styled.div`
  height: 100%;
  position: relative;
  flex: 1;

  &.up {
    label {
      ${LabelUpStyles}
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  padding-left: 18px;
  color: #0f191b;
  background-color: transparent;
  border: none;
  outline: none;
  transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
  &:focus,
  &:hover,
  &:active,
  &.up {
    padding-top: 10px;
    & ~ label {
      ${LabelUpStyles}
    }
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }
`;

export const Label = styled.label`
  font-size: 1.4rem;
  font-weight: 500;
  position: absolute;
  padding-left: 18px;
  color: ${colors.accept};
  pointer-events: none;
  left: 0;
  top: 5px;
  transition: top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1), font-size 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
`;

export const InvalidAlert = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: ${colors.cancel};
  position: absolute;
  bottom: -47px;
  text-align: left;
`;

export const InvalidText = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 1.2rem;
  color: ${colors.cancel};
  text-align: left;
`;