import React, { useContext } from 'react';
import styled from 'styled-components';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Body from 'containers/Body';
import Footer, { DefaultFooterContent } from 'containers/Footer';
import Card from 'containers/Card';
import { AcceptButton } from 'components/Buttons';
import { CLIENT_SERVICE_PHONE } from 'resources/constants/config';
import { Title, Strong, Text } from 'components/Texts';
import { I18nContext } from 'i18n';

const hrefTel = `tel:${CLIENT_SERVICE_PHONE}`;

const InnerBox = styled.div`
  margin: 3em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: 0 3em;
`;

const Update = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Layout>
      <Header back={true} />
      <Body>
        <div style={{ height: '70px' }}></div>
        <Title>{translate('UPDATE_TITLE')}</Title>
        <div style={{ height: '30px' }}></div>
        <Card>
          <InnerBox>
            <Text>{translate('UPDATE_MESSAGE')}</Text>
            <ButtonContainer>
              <a href={hrefTel}>
                <AcceptButton text={translate('CALL_TO_MODIFY')} />
              </a>
            </ButtonContainer>
          </InnerBox>
        </Card>
      </Body>
      <Footer>
        <DefaultFooterContent />
      </Footer>
    </Layout>
  );
};

export default Update;
