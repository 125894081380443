import { findCountryPhoneCode } from 'components/Inputs/CountrySelector/data';
import Types from './types';

const INITIAL_STATE = {
    reservation: {},
    client: {},
    isFetching: false,
    error: false,
    confirmationStatus: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START: return { ...state, isFetching: true }

        case Types.FETCH_SUCCESS: return { ...state, ...action.payload, isFetching: false, error: false }

        case Types.FETCH_ERROR: return { ...state, isFetching: false, error: true }

        case Types.CANCEL_SUCCESS: {
            const newReservation = { ...state.reservation, statusCode: "canceled" }

            return { ...state, reservation: newReservation }
        }

        case Types.UPDATE_CLIENT: {
            return { ...state, client: {...state.client, countryCode: findCountryPhoneCode(action.payload.phone.countryCode), phone: action.payload.phone.number, email: action.payload.email, }}
        }

        case Types.STRIPE_SUCCESS: return {...state, confirmationStatus: 'confirmed'}

        case Types.STRIPE_FAILED: return {...state, confirmationStatus: 'failed'}

        default: return state;
    }
}

export default reducer