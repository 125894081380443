import React from "react";
import styled from "styled-components";
import colors from "resources/constants/colors";
import Card from "containers/Card";

export const BaseButton = styled.button`
  width: 100%;
  padding: 15px 0px;
  border: 1px solid ${({ color }) => (color ? color : colors.accept)};
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: 1px solid ${({ color }) => (color ? color : colors.accept)};
  }
`;

export const ColorButton = styled(BaseButton)`
  background-color: ${({ color }) => (color ? color : colors.accept)};
  color: white;
  &:hover {
    background-color: white;
    color: ${({ color }) => (color ? color : colors.accept)};
  }
`;

const TransparentButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ color }) => (color ? color : colors.accept)};
  &:hover {
    background-color: ${({ color }) => (color ? color : colors.accept)};
    color: white;
  }
`;

const TransparentDarkButton = styled(TransparentButton)`
  color: white;
`;

export const AcceptButton = ({ text, onClick, disabled, type='button', className }) => (
  <Card className={className}>
    <ColorButton
      type={type}
      color={disabled ? colors.disabled : colors.accept}
      onClick={disabled ? ()=>{} : onClick}
      style={{ disabled: disabled }}
    >
      {!!text ? text : "Accept"}
    </ColorButton>
  </Card>
);

export const CancelButton = ({ text, dark = false, onClick, type='button', className }) => {
  const Button = dark ? TransparentDarkButton : TransparentButton;

  return (
    <Card className={className}>
      <Button type={type} color={colors.cancel} onClick={onClick}>
        {!!text ? text : "Cancel"}
      </Button>
    </Card>
  );
};

export const Link = styled.a`
  color: ${colors.accept};
  font-weight: bold;
  font-size: 1.2rem;
`;
