import React, { useContext } from "react";
import { I18nContext } from "i18n";
import { ClientDataContext } from "../../providers/ClientDataProvider";
import { Strong } from "components/Texts";
import { findCountryPhoneCode } from "components/Inputs/CountrySelector/data";
import { isValidNumber } from "utils/phone";
import { CustomBlueInput } from "components/Inputs";
import { CustomMobileBlueInput } from "components/Inputs";
import MobileIcon from "resources/img/mobile-transparent-icon.svg";
import EmailIcon from "resources/img/mail-transparent-icon.svg";
import { TextSmall } from "components/Texts";

const ContactInfoForm = () => {
  const { translate } = useContext(I18nContext);
  const [clientData, dispatch] = useContext(ClientDataContext);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    dispatch({
      type: "email",
      payload: email,
    });
  };

  const handleCountryCodeChange = (code) => {
    dispatch({
      type: "countryCode",
      payload: code,
    });
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    dispatch({
      type: "number",
      payload: value,
    });
  };

  return (
    <>
      <Strong style={{ maxWidth: "70vw", margin: "1rem auto" }} dangerouslySetInnerHTML={{__html: translate("STEP_1")}} />
      <CustomBlueInput
        title={translate("EMAIL")}
        icon={EmailIcon}
        inputProps={{value: clientData.email, onChange: handleEmailChange, required: true, name: 'mail', type: 'email', autoComplete: 'username' }}
        required={true}
      />
      <TextSmall style={{textAlign: 'left'}}>{translate('REQUIRED_FIELD')}</TextSmall>
      <div style={{height: '10px'}}></div>
      <CustomMobileBlueInput
          title={translate('MOBILE')}
          icon={MobileIcon}
          value={clientData.phone.number}
          country={clientData.phone.countryCode}
          onChange={handleNumberChange}
          onChangeCountry={handleCountryCodeChange}
          required={true}
          autoComplete={"new-password"}
          error={isValidNumber(
            findCountryPhoneCode(clientData.phone.countryCode),
            clientData.phone.number
          ) ?  undefined : translate('INVALID_PHONE')}
        />
    </>
  );
};

export default ContactInfoForm;
