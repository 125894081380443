const colors = {
  base: "#364456",
  textMain: "#0F191B",
  accept: "#388CFF",
  cancel: "#E84961",
  disabled : "#5F5F5F3D",
  lightGrey : "#0332693D",
  backgroundLightGrey: "#EDEDED",
  backgroundDarkBlue: "#364456",
 };

export default colors;